import { scrollTop } from '../utils/scroll';
import { isIE11 } from '../utils/helpers';

export function setupIntersect() {
    function onChange(changes, observer) {
        changes.forEach(change => {
            if (change.intersectionRatio > 0.5) {
                if (change.target.closest('.product-entrance')) {
                    const list = change.target.closest('.product-entrance').querySelector('.product-entrance__list');

                    if (list) {
                        if (list.childNodes[0] == change.target) {
                            list.classList.add('js-intersect');
                        }
                    }
                }

                change.target.classList.add('js-intersect');
                change.target.classList.remove('js-scrolled-by');
            } else {
                const elementTop = parseInt(change.target.getAttribute('data-top'));

                if (scrollTop > elementTop) {
                    change.target.classList.add('js-intersect', 'js-scrolled-by');
                } else {
                    change.target.classList.remove('js-scrolled-by');
                }
            }
        });
    }

    const intersectElements = document.querySelectorAll('[data-intersect]');

    if (!isIE11) {
        const config = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver(onChange, config);

        Array.from(intersectElements).forEach(element => {
            const elementTop = element.getBoundingClientRect().top + scrollTop;

            element.setAttribute('data-top',elementTop);
            observer.observe(element);
        });
    } else {
        Array.from(intersectElements).forEach(element => {
            element.classList.add('js-intersect');
        });
    }
}
