export function setupSliders(snapToItems = false) {
    const sliders = Array.from(document.querySelectorAll('.slider')).filter(x => x.querySelectorAll('.slider__item').length > 0);

    Array.from(sliders).forEach(slider => {
        const maxPxPrSecond = 10000;
        const sliderList = slider.querySelector('.slider__list');
        const sliderItems = slider.querySelectorAll('.slider__item');
        const sliderNavItems = slider.querySelectorAll('.slider__nav__item');
        const sliderNavPrev = slider.querySelector('.slider__button--prev');
        const sliderNavNext = slider.querySelector('.slider__button--next');
        const maxTranslate = 0;

        const itemWidth = parseFloat(getComputedStyle(sliderItems[0], null).getPropertyValue('width').replace('px', ''));
        let minTranslate = parseFloat(getComputedStyle(slider, null).getPropertyValue('width').replace('px', '')) - parseFloat(getComputedStyle(sliderList, null).getPropertyValue('width').replace('px', ''));

        if (snapToItems) {
            minTranslate = (parseFloat(getComputedStyle(sliderList, null).getPropertyValue('width').replace('px', '')) - itemWidth) * -1;
        }

        const itemMargin = parseInt(getComputedStyle(sliderItems[1], null).getPropertyValue('margin-left').replace('px', ''));
        const itemSnapStart = itemWidth / 2;
        let itemIndex = 0;
        let listAnimation = -1;

        slider.setAttribute('data-max-translate', maxTranslate);
        slider.setAttribute('data-min-translate', minTranslate);

        let move = false;
        let touch = false;

        // Start y and start x i s where whe start touching the screen. Only set once at touchstart and mousedown
        let startX = -1;
        let startY = -1;

        // Updated as we move the mouse (when button is down) or slide the screen
        let moveX = -1;
        let moveY = -1;

        // Used to set the direction - do we need to slide left or right
        let direction = -1;

        // Let's save the distance between startX and moveX here so we know how much to move it around
        let distanceX = -1;

        // This is used for the current move. This + pxMoved is what we want to translate the slider
        let translateX = -1;

        // Lets update this every time we move the slider. We don't want to start from 0 each time
        let pxMoved = 0;

        // Did we move at all? Or just click / touch once. If not we don't want to move at all
        let moved = false;

        // Let's take a look at how much px. we moved pr. second when moving. Maybe we can use that to calculate if we want to slide a bit mmore - and maybe even bounce if we move more than the slider alows.
        let startTime = -1;
        let endTime = -1;
        let time = -1;

        // Let's know if we are currently sliding some extra px
        let animating = false;

        // Array to contain the animations steps
        let animationSteps = [];

        // Only the last part where we bounce back. The rest is in the "getAnimationSteps"
        function getBounceSteps(bouncePx) {
            let lastMove = 0;
            let nextMove = 0;
            let bouncedExtra = 0;

            function getNextBounceMove() {
                if (lastMove == 0) {
                    nextMove = direction == 'left' ? 5 : -5;
                } else {
                    nextMove = lastMove * 1.1;
                }

                if (direction == 'left') {
                    if ((bouncedExtra + nextMove) > (bouncePx * -1)) {
                        nextMove = (bouncePx * -1) - bouncedExtra;
                    }
                } else if (direction == 'right') {
                    if ((bouncedExtra + nextMove) < (bouncePx * -1)) {
                        nextMove = (bouncePx * -1) - bouncedExtra;
                    }
                }

                lastMove = nextMove;

                return nextMove;
            }

            if (direction == 'left') {
                while (bouncedExtra < (bouncePx * -1)) {
                    nextMove = getNextBounceMove(lastMove);
                    bouncedExtra = bouncedExtra + nextMove;

                    animationSteps.push(nextMove);
                }
            } else if (direction == 'right') {
                while (bouncedExtra > (bouncePx * -1)) {
                    nextMove = getNextBounceMove(lastMove);
                    bouncedExtra = bouncedExtra + nextMove;

                    animationSteps.push(nextMove);
                }
            }
        }

        function clickSliderItem(e) {
            if (moved) {
                e.preventDefault();
            }
        }

        Array.from(sliderItems).forEach(item => {
            if (item.tagName.toLowerCase() == 'a') {
                item.addEventListener('click', clickSliderItem);
            }
        });

        function updateNav(index) {
            const activeSliderItems = Array.from(sliderNavItems).filter(x => x.classList.contains('js-active'));

            if (activeSliderItems.length == 1) {
                activeSliderItems[0].classList.remove('js-active');
            }

            sliderNavItems[index].classList.add('js-active');

            if (sliderNavPrev && sliderNavNext) {
                if (itemIndex == 0) {
                    sliderNavPrev.classList.add('js-disabled');
                    sliderNavNext.classList.remove('js-disabled');
                } else {
                    sliderNavPrev.classList.remove('js-disabled');

                    if (itemIndex + 1 == sliderItems.length) {
                        sliderNavNext.classList.add('js-disabled');
                    } else {
                        sliderNavNext.classList.remove('js-disabled');
                    }
                }
            }
        }

        // Get the animtionsteps to animate the move we made - including bounce mvoes (above) if we did it really fast ;-)
        function getAnimationSteps(moveExtra) {
            const maxBounce = slider.offsetWidth / 3;
            const maxPrFrame = 100;
            animationSteps = [];
            let lastMove = 0;
            let nextMove = 0;
            let movedTotal = 0;
            let bouncePx = 0;
            let bounce = false;

            if (direction == 'left') {
                if (translateX + moveExtra < minTranslate) {
                    bounce = true;
                }
            } else if (direction == 'right') {
                if (translateX + moveExtra > 0) {
                    bounce = true;
                }
            }

            if (bounce) {
                if (direction == 'left') {
                    itemIndex = sliderItems.length - 1;

                    // TODO: When bouncing its the opposite way, so no * -1 here... Remeber in getBounceSteps...
                    if ((pxMoved + moveExtra) < (minTranslate - maxBounce)) {
                        bouncePx = maxBounce * -1;
                    } else {
                        bouncePx = (pxMoved + moveExtra) - minTranslate;
                    }

                    if (pxMoved + moveExtra < minTranslate) {
                        moveExtra = minTranslate - pxMoved;
                        moveExtra = moveExtra + bouncePx;
                    }
                } else if (direction == 'right') {
                    itemIndex = 0;

                    if (pxMoved + moveExtra > (maxTranslate + maxBounce)) {
                        bouncePx = maxBounce;
                    } else {
                        bouncePx = (pxMoved + moveExtra);
                    }

                    if (pxMoved + moveExtra > maxTranslate) {
                        moveExtra = maxTranslate - pxMoved;
                        moveExtra = moveExtra + bouncePx;
                    }
                }

                updateNav(itemIndex);
            } else {
                if (snapToItems) {
                    if (direction == 'left') {
                        const newPosition = (moveExtra + pxMoved) * -1;
                        const newIndex = Math.round((newPosition + itemSnapStart) / itemWidth);

                        let moveTotal = 0;

                        if (newIndex == 0) {
                            moveTotal = 0;
                        } else {
                            if (sliderItems.length - 1 >= newIndex) {
                                moveTotal = (sliderItems[newIndex].offsetLeft * -1) - 10;
                            }
                        }

                        moveExtra = moveTotal - pxMoved;
                        itemIndex = newIndex;

                        updateNav(itemIndex);
                    } else if (direction == 'right') {
                        const newPosition = (moveExtra + pxMoved) * -1;
                        const newIndex = Math.round((newPosition + itemSnapStart) / itemWidth) - 1;

                        let moveTotal = 0;

                        if (newIndex == 0) {
                            moveTotal = 10;
                        } else {
                            if (sliderItems.length - 1 >= newIndex) {
                                moveTotal = (sliderItems[newIndex].offsetLeft * -1) + 10;
                            }
                        }

                        moveExtra = moveTotal - pxMoved;
                        itemIndex = newIndex;

                        updateNav(itemIndex);
                    }
                }
            }

            let movedExtra = 0;

            function getNextMove() {
                if (lastMove == 0) {
                    nextMove = direction == 'left' ? -5 : 5;
                } else {
                    nextMove = lastMove * 1.1;
                }

                if (direction == 'left') {
                    if ((nextMove * -1) > maxPrFrame) {
                        nextMove = maxPrFrame * -1;
                    }

                    if ((movedExtra + nextMove) < moveExtra) {
                        nextMove = moveExtra - movedExtra;
                    }
                } else if (direction == 'right') {
                    if (nextMove > maxPrFrame) {
                        nextMove = maxPrFrame;
                    }

                    if ((movedExtra + nextMove) > moveExtra) {
                        nextMove = moveExtra - movedExtra;
                    }
                }

                movedExtra = movedExtra + nextMove;
                lastMove = nextMove;

                return nextMove;
            }

            if (bounce) {
                getBounceSteps(bouncePx);
            }

            if (direction == 'left') {
                while (movedTotal > moveExtra) {
                    nextMove = getNextMove(lastMove);
                    movedTotal = movedTotal + nextMove;

                    animationSteps.push(nextMove);
                }

                animationSteps.push(5);
            } else if (direction == 'right') {
                while (movedTotal < moveExtra) {
                    nextMove = getNextMove(lastMove);
                    movedTotal = movedTotal + nextMove;

                    animationSteps.push(nextMove);
                }

                animationSteps.push(-5);
            }

            animationSteps = animationSteps.reverse();

            return animationSteps;
        }

        // Start slider (at touch start and mousedown)
        function startSlider(e) {
            move = true;
            startTime = new Date();

            if (e.changedTouches) {
                touch = true;

                startX = e.changedTouches[0].pageX;
                startY = e.changedTouches[0].pageY;
            } else {
                startX = e.clientX;
                startY = e.clientY;

                if (e.target.closest('a')) {
                    e.preventDefault();
                }

                document.addEventListener('mousemove', documentMouseMove);
            }
        }

        // Move slider (when swiping and moving mouse)
        function moveSlider(e) {
            if (e.touches) {
                if (e.touches.length == 1) {
                    move = true;
                    moved = true;

                    moveX = e.targetTouches[0].pageX;
                    moveY = e.targetTouches[0].pageY;
                }
            } else {
                moved = true;

                moveX = e.clientX;
                moveY = e.clientY;
            }

            if (move) {
                sliderList.setAttribute('data-barba-prevent', 'all');

                distanceX = startX - moveX;
                let distanceY = startY - moveY;

                direction = startX > moveX ? 'left' : 'right';

                distanceX = distanceX < 0 ? distanceX * -1 : distanceX;
                distanceY = distanceY < 0 ? distanceY * -1 : distanceY;

                if (distanceX > (distanceY / 2)) {
                    if (e.touches) {
                        e.preventDefault();
                    }

                    moveList();
                }
            }
        }

        // End slider (touch end and mouse up)
        function endSlider() {
            if (moved) {
                if (!touch) {
                    document.removeEventListener('mousemove', documentMouseMove);
                }

                pxMoved = translateX;

                endTime = new Date();

                if (startTime != -1) {
                    time = (endTime.getTime() - startTime.getTime()) / 1000;

                    let pxPrSecond = distanceX / time;
                    pxPrSecond = pxPrSecond >= 0 ? pxPrSecond : pxPrSecond * -1;

                    if (pxPrSecond > maxPxPrSecond) {
                        pxPrSecond = maxPxPrSecond;
                    }

                    if (pxPrSecond > 500) {
                        moveListExtra(pxPrSecond);
                    } else {
                        resetSlider();
                    }
                } else {
                    resetSlider();
                }
            }
        }

        // Reset slider variables
        function resetSlider() {
            if (listAnimation != -1) {
                cancelAnimationFrame(listAnimation);
                listAnimation = -1;
            }

            move = false;

            startX = -1;
            startY = -1;

            moveX = -1;
            moveY = -1;

            direction = -1;

            distanceX = -1;

            translateX = -1;

            touch = false;

            startTime = -1;
            endTime = -1;
            time = -1;

            animating = false;

            setTimeout(function () {
                moved = false;
                sliderList.removeAttribute('data-barba-prevent');
            }, 50);
        }

        // Move list - just when swiping and moving mouse around
        function moveList() {
            translateX = direction == 'left' ? distanceX * -1 : distanceX;

            let newTranslateX = translateX + pxMoved;
            newTranslateX = newTranslateX > 0 ? 0 : newTranslateX;
            newTranslateX = newTranslateX < minTranslate ? minTranslate : newTranslateX;

            sliderList.style.transform = `translate3d(${newTranslateX}px,0,0)`;

            moved = true;
            translateX = newTranslateX;
        }

        function sliderTransitionEnd() {
            sliderList.style.transition = null;
            sliderList.removeEventListener('transitionend', sliderTransitionEnd);
        }

        function clickNavItem(e) {
            const item = e.target.closest('.slider__nav__item');

            if (item) {
                const index = Array.from(sliderNavItems).indexOf(item);

                if (index != itemIndex) {
                    const newX = ((index * itemWidth) + (index * itemMargin)) * -1;

                    sliderList.style.transition = 'transform 0.5s ease-in-out';
                    sliderList.style.transform = `translate3d(${newX}px,0,0)`;

                    sliderList.addEventListener('transitionend', sliderTransitionEnd);

                    moved = true;
                    pxMoved = newX;

                    itemIndex = index;

                    updateNav(itemIndex);
                }
            }
        }

        Array.from(sliderNavItems).forEach(item => {
            item.addEventListener('click', clickNavItem);
        });

        if (sliderNavPrev) {
            sliderNavPrev.addEventListener('click', function () {
                if (itemIndex != 0) {
                    const index = itemIndex - 1;
                    const newX = ((index * itemWidth) + (index * itemMargin)) * -1;

                    sliderList.style.transition = 'transform 0.5s ease-in-out';
                    sliderList.style.transform = `translate3d(${newX}px,0,0)`;

                    sliderList.addEventListener('transitionend', sliderTransitionEnd);

                    moved = true;
                    pxMoved = newX;

                    itemIndex = index;

                    updateNav(itemIndex);
                }
            });
        }

        if (sliderNavNext) {
            sliderNavNext.addEventListener('click', function () {
                if (itemIndex + 1 < sliderItems.length) {
                    const index = itemIndex + 1;
                    const newX = ((index * itemWidth) + (index * itemMargin)) * -1;

                    sliderList.style.transition = 'transform 0.5s ease-in-out';
                    sliderList.style.transform = `translate3d(${newX}px,0,0)`;

                    sliderList.addEventListener('transitionend', sliderTransitionEnd);

                    moved = true;
                    pxMoved = newX;

                    itemIndex = index;

                    updateNav(itemIndex);
                }
            });
        }

        // Move list extra if we did it really fast ;-)
        function moveListExtra(pxPrSecond) {
            animating = true;

            let moveExtra = (pxPrSecond / 100000000) * (pxPrSecond * pxPrSecond);

            if (touch) {
                const _pxPrSecond = pxPrSecond * 2;
                moveExtra = (_pxPrSecond / 100000000) * (_pxPrSecond * _pxPrSecond);
            }

            if (direction == 'left') {
                moveExtra = moveExtra * -1;
            }

            let newTranslateX = -1;
            let movedExtra = 0;
            let animationStepIndex = 0;

            const animationSteps = getAnimationSteps(moveExtra);

            function animateList() {
                if (animationStepIndex + 1 >= animationSteps.length) {
                    if (newTranslateX != -1) {
                        translateX = newTranslateX;
                        pxMoved = translateX;
                    }

                    resetSlider();
                } else {
                    movedExtra = movedExtra + animationSteps[animationStepIndex];
                    animationStepIndex++;
                    movedExtra = movedExtra;

                    newTranslateX = translateX + movedExtra;
                    sliderList.style.transform = `translate3d(${newTranslateX}px,0,0)`;

                    requestAnimationFrame(animateList);
                }
            }

            listAnimation = requestAnimationFrame(animateList);
        }

        // Events
        function sliderTouchStart(e) {
            if (!animating) {
                resetSlider();

                if (e.touches.length == 1) {
                    startSlider(e);
                }
            }
        }

        function sliderTouchMove(e) {
            if (!animating) {
                moveSlider(e);
            }
        }

        function sliderTouchEnd(e) {
            if (!animating) {
                endSlider();
            }
        }

        function sliderTouchCancel(e) {
            if (!animating) {
                resetSlider();
            }
        }

        function documentMouseUp(e) {
            if (!animating) {
                endSlider();
            }
        }

        function documentMouseMove(e) {
            if (!animating) {
                moveSlider(e);
            }
        }

        function sliderMouseDown(e) {
            if (!animating) {
                resetSlider();
                startSlider(e);
            }
        }

        sliderList.addEventListener('touchstart', sliderTouchStart);
        sliderList.addEventListener('touchmove', sliderTouchMove);
        sliderList.addEventListener('touchend', sliderTouchEnd);
        sliderList.addEventListener('touchcancel', sliderTouchCancel);
        document.addEventListener('mouseup', documentMouseUp);
        sliderList.addEventListener('mousedown', sliderMouseDown);
    });
}