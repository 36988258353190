import { onScroll, scrollTop } from '../utils/scroll';
import { currentWindowHeight } from '../utils/windowResize';

export function setupCollageElements() {
    const collageHeaderElements = document.querySelectorAll('.header .collage-01__boy,.header .collage-01__line-blue,.header .collage-02__boy,.header .collage-02__line-blue,.header .collage-03__girl,.header .collage-03__line-orange,.header .collage-04__girl,.header .collage-04__line-pink-light');
    const collageHeaderDoubleElements = document.querySelectorAll('.header .collage-01__circle-green,.header .collage-01__butterflies,.header .collage-02__circle-blue,.header .collage-02__flamengos,.header .collage-02__flying-flamengo,.header .collage-03__circle-purple,.header .collage-03__leaves,.header .collage-04__circle-yellow,.header .collage-04__umbrellas');
    const collageModuleElements = document.querySelectorAll('.module__collage-element');

    const minY = currentWindowHeight * -1;
    const maxY = 0;

    Array.from(collageModuleElements).forEach(element => {
        const elementTop = element.getBoundingClientRect().top + scrollTop;
        element.setAttribute('data-top', elementTop);
    });

    onScroll(() => {
        Array.from(collageModuleElements).forEach(element => {
            const elementTop = parseInt(element.getAttribute('data-top') - (currentWindowHeight / 3));

            if (scrollTop > elementTop) {
                let tY = ((scrollTop - elementTop) / 2) * -1;

                if (tY >= minY && tY <= maxY) {
                    element.style.transform = `translateY(${tY}px)`;
                } else {
                    if (tY < minY) {
                        tY = minY
                    } else if (tY > maxY) {
                        tY = maxY;
                    }

                    element.style.transform = `translateY(${tY}px)`;
                }
            }
        });

        Array.from(collageHeaderElements).forEach(element => {
            let tY = (scrollTop / 2) * -1;

            if (tY >= minY && tY <= maxY) {
                element.style.transition = 'initial';
                element.style.transform = `translateY(${tY}px)`;
            } else {
                if (tY < minY) {
                    tY = minY
                } else if (tY > maxY) {
                    tY = maxY;
                }

                element.style.transition = 'initial';
                element.style.transform = `translateY(${tY}px)`;
            }
        });

        Array.from(collageHeaderDoubleElements).forEach(element => {
            let tY = scrollTop * -1;

            if (tY >= minY && tY <= maxY) {
                element.style.transition = 'initial';
                element.style.transform = `translateY(${tY}px)`;
            } else {
                if (tY < minY) {
                    tY = minY
                } else if (tY > maxY) {
                    tY = maxY;
                }

                element.style.transition = 'initial';
                element.style.transform = `translateY(${tY}px)`;
            }
        });
    });
}