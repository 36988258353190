import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

const burgerNav = document.querySelector('.nav__burger');
const burgerNavBackgroundImage = Array.from(document.querySelectorAll('.nav__burger__background-image'));
let activeLink;
let hoverLink;
let activeBackgroundImage;
let leaveTimer = -1;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');
        nav.classList.remove('js-sub-nav-open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

        burgerNav.classList.remove('js-sub-nav-open');

        if (activeLink) {
            activeLink.classList.remove('js-active');
            activeLink = null;
            hoverLink = null;
            activeBackgroundImage = null;
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }

    function changeImage() {
        if (hoverLink.hasAttribute('data-background-image')) {
            const inactiveBurgerNavBackgroundImage = burgerNavBackgroundImage.filter(x => !x.classList.contains('js-active'))[0];
            const activeBurgerNavBackgroundImage = burgerNavBackgroundImage.filter(x => x.classList.contains('js-active'))[0];

            if (hoverLink.getAttribute('data-background-image') != activeBackgroundImage) {
                const image = document.createElement('img');

                if (hoverLink.getAttribute('data-background-image') != inactiveBurgerNavBackgroundImage.getAttribute('data-background-image')) {
                    image.src = hoverLink.getAttribute('data-background-image');

                    if (image.complete) {
                        inactiveBurgerNavBackgroundImage.style.backgroundImage = `url(${image.src})`;
                        inactiveBurgerNavBackgroundImage.setAttribute('data-background-image', hoverLink.getAttribute('data-background-image'));
                        inactiveBurgerNavBackgroundImage.classList.add('js-active');
                        inactiveBurgerNavBackgroundImage.classList.add('js-show');

                        activeBurgerNavBackgroundImage.classList.remove('js-active');
                        activeBurgerNavBackgroundImage.classList.remove('js-show');
                        //activeBurgerNavBackgroundImage.style.backgroundImage = null;

                        activeBackgroundImage = hoverLink.getAttribute('data-background-image');

                        burgerNav.classList.remove('js-click');
                        //burgerNavBackgroundImage.removeEventListener('transitionend', changeImage);
                    } else {
                        image.onload = function () {
                            inactiveBurgerNavBackgroundImage.style.backgroundImage = `url(${image.src})`;
                            inactiveBurgerNavBackgroundImage.setAttribute('data-background-image', hoverLink.getAttribute('data-background-image'));
                            inactiveBurgerNavBackgroundImage.classList.add('js-active');
                            inactiveBurgerNavBackgroundImage.classList.add('js-show');

                            activeBurgerNavBackgroundImage.classList.remove('js-active');
                            activeBurgerNavBackgroundImage.classList.remove('js-show');
                            //activeBurgerNavBackgroundImage.style.backgroundImage = null;

                            activeBackgroundImage = hoverLink.getAttribute('data-background-image');

                            burgerNav.classList.remove('js-click');
                            //burgerNavBackgroundImage.removeEventListener('transitionend', changeImage);
                        };
                    }
                } else {
                    inactiveBurgerNavBackgroundImage.classList.add('js-active');
                    inactiveBurgerNavBackgroundImage.classList.add('js-show');

                    activeBurgerNavBackgroundImage.classList.remove('js-active');
                    activeBurgerNavBackgroundImage.classList.remove('js-show');

                    activeBackgroundImage = hoverLink.getAttribute('data-background-image');

                    burgerNav.classList.remove('js-click');

                }
            }
        }
    }

    function enterBurgerNavItem(e) {
        hoverLink = e.currentTarget;
        burgerNav.classList.add('js-hover');

        if (hoverLink.classList.contains('has-children')) {
            if (!burgerNav.classList.contains('js-sub-nav-open')) {
                if (leaveTimer != -1) {
                    clearTimeout(leaveTimer);
                    leaveTimer = -1;
                }

                if (hoverLink.hasAttribute('data-background-image')) {
                    changeImage();
                }
            }
        }
    }

    function leaveBurgerNavItem(e) {
        const link = e.currentTarget;
        burgerNav.classList.remove('js-hover');

        if (leaveTimer != -1) {
            clearTimeout(leaveTimer);
            leaveTimer = -1;
        }

        leaveTimer = setTimeout(function() {
            const activeBurgerNavBackgroundImage = burgerNavBackgroundImage.filter(x => x.classList.contains('js-active'))[0];

            if (activeBurgerNavBackgroundImage) {
                activeBurgerNavBackgroundImage.classList.remove('js-show');
                activeBackgroundImage = null;
                leaveTimer = -1;
            }
        },150);
    }

    function clickBurgerNavItem(e) {
        const item = e.currentTarget;

        if (burgerNav.classList.contains('js-sub-nav-open') && item.getAttribute('data-background-image') != activeBackgroundImage) {
            burgerNav.classList.add('js-click');
            changeImage();
        }

        if (activeLink) {
            if (activeLink != item) {
                item.classList.add('js-active');
                activeLink.classList.remove('js-active');
                burgerNav.classList.add('js-sub-nav-open');
                nav.classList.add('js-sub-nav-open');

                activeLink = item;
            } else {
                activeLink.classList.remove('js-active');
                burgerNav.classList.remove('js-sub-nav-open');
                nav.classList.remove('js-sub-nav-open');

                activeLink = null;
            }
        } else {
            item.classList.add('js-active');
            burgerNav.classList.add('js-sub-nav-open');
            nav.classList.add('js-sub-nav-open');

            activeLink = item;
        }
    }

    function clickBack(e) {
        const item = e.currentTarget;

        if (activeLink) {
            activeLink.classList.remove('js-active');
            activeLink = null;
            hoverLink = null;
            activeBackgroundImage = null;
        }

        burgerNav.classList.remove('js-sub-nav-open');
        nav.classList.remove('js-sub-nav-open');
    }

    if (burgerNav != undefined) {
        const burgerNavItems = burgerNav.querySelectorAll('a.level-1');
        const burgerNavSubBacks = burgerNav.querySelectorAll('li.nav__burger__back');

        Array.from(burgerNavItems).forEach(item => {
            item.addEventListener('mouseenter', enterBurgerNavItem);
            item.addEventListener('mouseleave', leaveBurgerNavItem);
            item.addEventListener('click', clickBurgerNavItem);
        });

        Array.from(burgerNavSubBacks).forEach(item => {
            item.addEventListener('click',clickBack);
        });
    }

    const navSearchForm = document.querySelector('.nav__search form');
    const navSearchIcon = document.querySelector('.nav__search svg');
    const navSearchField = document.querySelector('.nav__search input[type="text"]');

    if (navSearchIcon && navSearchField) {
        navSearchIcon.addEventListener('click',function() {
            if (navSearchForm.classList.contains('js-show')) {
                navSearchForm.classList.remove('js-show');
            } else {
                navSearchForm.classList.add('js-show');
                navSearchField.focus();
            }
        });
    }

    if (navSearchField) {
        navSearchField.addEventListener('blur',function() {
            navSearchForm.classList.remove('js-show');
        });

        navSearchField.addEventListener('focus', function () {
            navSearchForm.classList.add('js-show');
        });
    }

    const navBurgerSearchForm = document.querySelector('.nav__burger__search form');
    const navBurgerSearchIcon = document.querySelector('.nav__burger__search svg');
    const navBurgerSearchField = document.querySelector('.nav__burger__search input[type="text"]');

    if (navBurgerSearchIcon && navBurgerSearchField) {
        navBurgerSearchIcon.addEventListener('click', function () {
            if (navBurgerSearchForm.classList.contains('js-show')) {
                navBurgerSearchForm.classList.remove('js-show');
            } else {
                navBurgerSearchForm.classList.add('js-show');
                navBurgerSearchField.focus();
            }
        });
    }

    if (navBurgerSearchField) {
        navBurgerSearchField.addEventListener('blur', function () {
            navBurgerSearchForm.classList.remove('js-show');
        });

        navBurgerSearchField.addEventListener('focus', function () {
            navBurgerSearchForm.classList.add('js-show');
        });
    }

    const navSupport = document.querySelector('.nav__support');

    if (navSupport) {
        const navSupportUl = navSupport.querySelector('ul');

        if (navSupportUl) {
            navSupport.addEventListener('click',function() {
                if (navSupportUl.classList.contains('js-show')) {
                    navSupportUl.classList.remove('js-show');
                } else {
                    navSupportUl.classList.add('js-show');
                }
            });
        }
    }
}
