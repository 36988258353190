import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, debounce, isTouchDevice, isIE11 } from './utils/helpers';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupTabs } from './components/tabs';
import { setupSliders } from './components/slider';
//import { watchFormFields } from './components/form';
import { setupProductEntrances } from './components/productentrance';
import { setupIntersect } from './components/intersect';
import { setupCollageElements } from './components/collage-element';
import { setupFormToggleFields } from './components/form';

function init() {
    document.body.classList.remove('standby');

    function endAnimation() {
        document.body.classList.add('js-animation-done');
    }

    if (document.body.getAttribute('data-animate').toLowerCase() == 'true') {
        //document.body.classList.add('page-load-animation');

        setTimeout(function() {
            const navLogo = document.querySelector('.nav__logo');

            if (navLogo) {
                navLogo.addEventListener('transitionend',endAnimation);
            }
        },1000);
    }

    if (isTouchDevice()) {
        document.body.classList.add('js-is-touch');
    }

    if (isIE11) {
        document.body.classList.add('js-ie-11');
    }

    setupAnchorLinkScroll();

    setupNav('.nav', false);

    onReady(() => {
        initVhUnitOverwrite();

        setupLazyLoading();

        setupAccordions();

        setupFilters();

        setupOverlay();

        setupVideos();

        setupTabs();

        setupSliders(true);

        //watchFormFields();

        setupProductEntrances();

        setupIntersect();

        setupCollageElements();

        setupFormToggleFields();
    });

    window.addEventListener('load', () => {
        svg4everybody();
    });
}

init();
