import { isTouchDevice } from '../utils/helpers';

export function setupProductEntrances() {
    function enterItem(e) {
        const paragraph = e.currentTarget.querySelector('p');
        const height = paragraph.scrollHeight;
        paragraph.style.height = `${height}px`;
    }

    function leaveItem(e) {
        const paragraph = e.currentTarget.querySelector('p');
        paragraph.style.height = null;
    }

    if (!isTouchDevice()) {
        const productEntranceItems = document.querySelectorAll('.product-entrances li');

        Array.from(productEntranceItems).forEach(item => {
            item.addEventListener('mouseenter',enterItem);
            item.addEventListener('mouseleave',leaveItem);
        });
    }
}