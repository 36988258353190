
const classNames = {
    activeBtn: 'filter__tab--active',
    hiddenItem: 'hidden'
};

/**
 *
 * @param {HTMLElement} container
 *
 * @constructor
 */
export class Filter {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container - Element containing filter options (Could be .filter__tabs or .filter__select)
     */
    constructor(container) {
        if (container) {
            this.dom = {
                container,
                module: container.closest('.module'),
                filters: container.querySelectorAll('[data-filter]'),
                allFilter: container.querySelector('[data-filter="*"]'),
                targetContainer: document.body.querySelector(container.getAttribute('data-target-container'))
            };

            this.settings = {
                filterType:  container.getAttribute('data-action')
            };

            if (this.dom.targetContainer) {
                for (let i = 0; i < this.dom.filters.length; i++) {

                    const filter = this.dom.filters[i];
                    const itemCountReplace = filter.getAttribute('data-item-count-replace');

                    if (filter.tagName === 'BUTTON') {
                        filter.addEventListener('click', e => this.filterHandler(e));
                    } else {
                        filter.addEventListener('change', e => this.filterHandler(e));

                        if (itemCountReplace) {
                            this.replaceWithNumberOfItems(filter, itemCountReplace);
                        }
                    }
                }

            } else {
                window.console.warn('Can\'t find [data-target-container] on filter container: ', container);
            }
        } else {
            window.console.warn('Filter container missing');
        }
    }

    static deselectRadio(e) {
        e.target.checked = !e.target.checked;
        e.target.removeEventListener('click', Filter.deselectRadio);

        this.filterHandler(e);
    }

    /**
     * Filter function that compares selected filter values with filter-values on filter-item.
     * Added hidden-class to items that hasn't got the selected filter value.
     *
     * @param {HTMLElement} e - event (click or change event
     */
    filterHandler(e) {
        const filter = e.target;
        const filterItems = this.dom.targetContainer.querySelectorAll('[data-filter-value]');
        const isButton = filter.tagName === 'BUTTON';
        const filterValue = isButton ? filter.getAttribute('data-filter') : filter.value;

        if (filterValue == '*') {
            if (this.dom.module) {
                this.dom.module.classList.remove('js-filtered');
            }
        } else {
            if (this.dom.module) {
                this.dom.module.classList.add('js-filtered');
            }
        }

        if (isButton) {
            if (this.settings.filterType === 'filter-switch') {

                for (let i = 0; i < this.dom.filters.length; i++) {
                    this.dom.filters[i].classList.remove(classNames.activeBtn);
                }

                filter.classList.add(classNames.activeBtn);
            } else {
                if (this.dom.allFilter && filter != this.dom.allFilter) {
                    this.dom.allFilter.classList.remove(classNames.activeBtn);
                } else {
                    for (let i = 0; i < this.dom.filters.length; i++) {
                        if (this.dom.filters[i].tagName === 'SELECT') {
                            // This is a small hack to avoid misleeding results if selector is not reset
                            // assumes that your selector has a default value of '*'
                            this.dom.filters[i].value = '*';
                        } else {
                            this.dom.filters[i].classList.remove(classNames.activeBtn);
                        }
                    }
                }

                if (!filter.classList.contains(classNames.activeBtn)) {
                    filter.classList.add(classNames.activeBtn);
                } else {
                    filter.classList.remove(classNames.activeBtn);
                }
            }
        } else if (filter.type === 'radio') {
            const name = filter.name;
            const siblingRadios = this.dom.container.querySelectorAll(`[name="${name}"]`);

            for (let r = 0; r < siblingRadios.length; r++) {
                const radio = siblingRadios[r];
                radio.removeEventListener('click', Filter.deselectRadio);
            }

            filter.addEventListener('click', Filter.deselectRadio.bind(this));
        }

        if (this.settings.filterType === 'filter-switch') {
            for (let i = 0; i < filterItems.length; i++) {
                const item = filterItems[i];
                let itemFilterValues = item.getAttribute('data-filter-value').split(',');
                const cleanFilterValue = filterValue.trim().toLowerCase();
                itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                if (filterValue !== '*' && itemFilterValues.indexOf(cleanFilterValue) === -1) {
                    item.classList.add(classNames.hiddenItem);
                } else {
                    item.classList.remove(classNames.hiddenItem);
                }
            }
        } else {
            const filterValues = Filter.getSetValues(this.dom.filters);
            const isComboFilter = this.settings.filterType === 'filter-combo';

            for (let i = 0; i < filterItems.length; i++) {
                const item = filterItems[i];
                let itemFilterValues = item.getAttribute('data-filter-value').split(',');
                let showItem = !isComboFilter;

                itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                if (filter === this.dom.allFilter && this.dom.allFilter.classList.contains(classNames.activeBtn)) {
                    showItem = true;
                } else {
                    for (let i = 0; i < filterValues.length; i++) {
                        const value = filterValues[i];
                        const cleanValue = value.trim().toLowerCase();

                        if (isComboFilter) {
                            if (itemFilterValues.indexOf(cleanValue) > -1) {
                                showItem = true;
                                break;
                            }
                        } else if (cleanValue != '*' && itemFilterValues.indexOf(cleanValue) === -1) {
                            showItem = false;
                        }
                    }
                }

                if (!showItem) {
                    item.classList.add(classNames.hiddenItem);
                } else {
                    item.classList.remove(classNames.hiddenItem);
                }
            }
        }

        // Hide empty Filter sections
        const sections = this.dom.targetContainer.querySelectorAll('[data-filter-section]');
        if (sections.length) {
            for (let s = 0; s < sections.length; s++) {
                const section = sections[s];
                const itemsShownInSection = section.querySelectorAll('[data-filter-value]:not(.hidden)');

                if (itemsShownInSection.length < 1) {
                    section.classList.add(classNames.hiddenItem);
                } else {
                    section.classList.remove(classNames.hiddenItem);
                }
            }
        }
    }

    static getSetValues(filters) {
        const valArray = [];

        // loop through list of filters
        for (let i  = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            if (filter.tagName === 'SELECT') {
                if (filter.value !== '*' && filter.value !== '') {
                    valArray.push(filter.value);
                }
            } else if (filter.tagName === 'BUTTON') {
                if (filter.classList.contains(classNames.activeBtn)) {
                    valArray.push(filter.getAttribute('data-filter'));
                }
            } else {
                if (filter.checked && filter.value !== '*') { // radio checked?
                    valArray.push(filter.value); // if so, add its value to array
                }
            }
        }
        return valArray; // return value of checked radio or undefined if none checked
    }

    replaceWithNumberOfItems(element, replaceValue) {
        const options = element.children;
        const items = this.dom.targetContainer.querySelectorAll('[data-filter-value]');

        if (options) {
            for (let i = 0; i < options.length; i++) {
                const option = options[i];

                if (option.value !== '*' && option.value !== '') {
                    let count = 0;
                    const optionStr = option.innerText;

                    for (let i = 0; i < items.length; i++) {
                        let itemFilterValues = items[i].getAttribute('data-filter-value').split(',');

                        itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                        const cleanValue = option.value.trim().toLowerCase();

                        if (itemFilterValues.indexOf(cleanValue) > -1) {
                            count++;
                        }
                    }

                    option.innerText = optionStr.replace(replaceValue, count);
                }
            }
        }
    }
}

export function setupFilters(selector = '[data-action*="filter"]') {
    const filterContainers = document.body.querySelectorAll(selector);

    for (let i = 0; i < filterContainers.length; i++) {
        void new Filter(filterContainers[i]);
    }
}
